<template>
    <CommitteeForm
        base_url="/committee"
        base_path="/committee"
        form_type="new"
        after_store_url="/committee/gestione/"
    />
</template>

<script>
 import CommitteeForm from "@/view/components/CommitteeForm.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     data() {
         return {
         }
     },

     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Comitati", route: "/committee/index" },
             { title: "Nuovo Comitato" }
         ]);
     },

     components: {
         CommitteeForm,
     },
 };
</script>
