<template>
    <SmartForm
        ref="comitatoForm"
        :base_url="base_url"
        :base_path="base_path"
        :form_type="form_type"
        :values_url="values_url"
        :after_store_url="after_store_url"
        :after_update_url="after_update_url"
        :redirect_after_save="redirect_after_save"
        :item_id="item_id"
    >
        <template v-if="hasTitleSlot" v-slot:title>
            <slot name="title"></slot>
        </template>
        <template v-if="hasToolbarSlot" v-slot:toolbar>
            <slot name="toolbar"></slot>
        </template>
        <template v-if="hasFooterSlot" v-slot:footer>
            <slot name="footer"></slot>
        </template>
    </SmartForm>
</template>

<script>
 import ApiService from "@/core/services/api.service";
 import SmartForm from "@/view/components/SmartForm.vue";

 export default {
     name: 'CommitteeForm',

     props: ['form_type', 'base_url', 'base_path', 'values_url', 'after_store_url', 'after_update_url', 'redirect_after_save', 'item_id'],

     data() {
         return {
             options_loaded: false,
             originalFields: [],
         }
     },
     computed: {
         hasTitleSlot () {
             return !!this.$slots['title']
         },

         hasToolbarSlot () {
             return !!this.$slots['toolbar']
         },

         hasFooterSlot () {
             return !!this.$slots['footer']
         },
     },

     mounted() {
         this.mountWatches();
     },

     components: {
         SmartForm,
     },

     methods: {
         mountWatches() {
             this.$watch(
                 () => {
                     if (this.options_loaded) {
                         return false;
                     }

                     return this.$refs.comitatoForm.options_loaded;
                 },
                 (value) => {
                     if (value) {
                         for(let i in this.$refs.comitatoForm.fields) {
                             this.originalFields.push(JSON.parse(JSON.stringify(this.$refs.comitatoForm.fields[i])));
                         }
                     }
                 }
             );

             this.$watch(
                 () => {
                     let fields = this.$refs.comitatoForm.fields;

                     if (fields.length)
                     {
                         if (this.$refs.comitatoForm.form_type === 'edit')
                         {
                             if (this.$refs.comitatoForm.values_loaded === null || this.$refs.comitatoForm.values_loaded === false) {
                                 return false;
                             }
                         }

                         let field = this.findField('idregion');
                         let value = this.getFieldValue(field);

                         if (typeof value !== "undefined" && value && value.id) return value.id;

                         return null;
                     }
                     else return null;
                 },
                 (newValue, oldValue) => {
                     let doUpdate;
                     let fieldProvstate = null;
                     let fieldProvstateValue = null;

                     if (newValue === false) {
                         doUpdate = false;
                     }
                     else {
                         doUpdate = true;
                         if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                         if (oldValue === false) {
                             fieldProvstate = this.findField('idprovstate');
                             fieldProvstateValue = JSON.parse(JSON.stringify(fieldProvstate.value));
                         }
                     }

                     if (doUpdate) {
                         let vm = this;
                         let value = { id: newValue };
                         if (value) {
                             vm.loadOptions('provstates', 'idregion', value.id, 'idprovstate',
                                            function(options) {
                                                options.unshift({
                                                    id: 0,
                                                    provstatecode: 'Seleziona una provincia',
                                                });
                                            },
                                            function(options) {
                                                fieldProvstate = vm.findField('idprovstate');

                                                if (fieldProvstate) {
                                                    vm.$set(fieldProvstate, 'filter_options', options);

                                                    if (typeof fieldProvstate.value !== "undefined") {
                                                        if (fieldProvstateValue !== null) {
                                                            fieldProvstate.value = fieldProvstateValue;
                                                        }
                                                        else {
                                                            fieldProvstate.value = JSON.parse(JSON.stringify(options[0]));
                                                        }
                                                    }
                                                }
                                            }
                             );
                         }
                     }
                 }
             );

             this.$watch(
                 () => {
                     let fields = this.$refs.comitatoForm.fields;

                     if (fields.length)
                     {
                         if (this.$refs.comitatoForm.form_type === 'edit')
                         {
                             if (this.$refs.comitatoForm.values_loaded === null || this.$refs.comitatoForm.values_loaded === false) {
                                 return false;
                             }
                         }

                         let field = this.findField('idprovstate');
                         let value = this.getFieldValue(field);

                         if (typeof value !== "undefined" && value && value.id) return value.id;

                         return null;
                     }
                     else return null;
                 },
                 (newValue, oldValue) => {
                     let doUpdate;
                     let fieldCity = null;
                     let fieldCityValue = null;

                     if (newValue === false) {
                         doUpdate = false;
                     }
                     else {
                         doUpdate = true;
                         if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                         if (oldValue === false) {
                             fieldCity = this.findField('idcity');
                             fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value));
                         }
                     }

                     if (doUpdate) {
                         let vm = this;
                         let value = { id: newValue };
                         if (value) {
                             vm.loadOptions('cities', 'idprovstate', value.id, 'idcity',
                                            function(options) {
                                                options.unshift({
                                                    id: 0,
                                                    cityname: 'Seleziona una città',
                                                });
                                            },
                                            function (options) {
                                                fieldCity = vm.findField('idcity');

                                                if (fieldCity) {
                                                    vm.$set(fieldCity, 'filter_options', options);

                                                    if (typeof fieldCity.value !== "undefined") {
                                                        if (fieldCityValue !== null) {
                                                            fieldCity.value = fieldCityValue;
                                                        }
                                                        else {
                                                            fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                                        }
                                                    }
                                                }
                                            }
                             );
                         }
                     }
                 }
             );
         },

         findField(name) {
             return this.$refs.comitatoForm.fields.find(item => item.name === name);
         },

         getFieldValue(field) {
             if (field) {
                 let value = typeof field.value !== "undefined" ? field.value : null;

                 if (value !== null) {
                     return (JSON.parse(JSON.stringify(value)));
                 }
             }

             return null;
         },

         setFieldVisible(name, visible) {
             let fieldIndex = this.$refs.comitatoForm.fields.findIndex(item => item.name === name);
             if (fieldIndex) {
                 this.$set(this.$refs.comitatoForm.fields[fieldIndex], 'is_visible_for_store', visible);
                 this.$set(this.$refs.comitatoForm.fields[fieldIndex], 'is_visible_for_update', visible);
             }
         },

         setFieldAttribute(name, attribute, value) {
             let fieldIndex = this.$refs.comitatoForm.fields.findIndex(item => item.name === name);
             if (fieldIndex && typeof this.$refs.comitatoForm.fields[fieldIndex] !== "undefined") {
                 this.$set(this.$refs.comitatoForm.fields[fieldIndex], attribute, value);
             }
         },

         showSteps(visibleSteps) {
             this.$set(this.$refs.comitatoForm, 'availableSteps', visibleSteps);

             for(let i in this.$refs.comitatoForm.steps) {
                 this.$set(this.$refs.comitatoForm.steps[i], 'visible', false);
             }

             if (this.$refs.comitatoForm.steps.length) {
                 let count = 0;
                 for(let i in visibleSteps) {
                     count++;
                     this.$set(this.$refs.comitatoForm.steps[visibleSteps[i]], 'visible', true);
                     this.$set(this.$refs.comitatoForm.steps[visibleSteps[i]], 'step', count.toString());
                 }
             }
         },

         resetFields() {
             for(let i in this.originalFields) {
                 let field = this.findField(this.originalFields[i].name);

                 this.setFieldAttribute(this.originalFields[i].name, 'is_visible_for_store', this.originalFields[i].is_visible_for_store);
                 this.setFieldAttribute(this.originalFields[i].name, 'is_visible_for_update', this.originalFields[i].is_visible_for_update);

                 this.setFieldAttribute(this.originalFields[i].name, 'nullable', this.originalFields[i].nullable);
             }
         },

         loadOptions(type, parameterName, parameterValue, fieldName, callbackEmptyOption, callbackOptions = null) {
             let url = '/committee/load-options?type=' + type + '&' + parameterName + '=' + parameterValue;

             ApiService.query(url)
                       .then((response) => {
                           let options = response.data;

                           if (typeof options === "undefined") options = [];

                           callbackEmptyOption(options);

                           if (callbackOptions !== null) {
                               callbackOptions(options);
                           }
                           else {
                               this.setFieldAttribute(fieldName, 'filter_options', options);
                           }
                       })
                       .catch((error) => {
                           console.log(error);

                           let options = [];

                           callbackEmptyOption(options);

                           if (callbackOptions !== null) {
                               callbackOptions(options);
                           }
                           else {
                               this.setFieldAttribute(fieldName, 'filter_options', options);
                           }
                       });
         },
     },
 };
</script>
